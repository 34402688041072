//引入request.js文件
import request from "./request";

//获取自动提现配置
export function getUserCashConfig(data) {
  return request({
    url: '/sys/users/getUserCashConfig',
    method: 'post',
    data: data
  })
}
//添加自动提现配置
export function getUserCashConfigInsert(data) {
  return request({
    url: '/sys/users/getUserCashConfigInsert',
    method: 'post',
    data: data
  })
}


//获取可绑定的子商户列表
export function subMerchantList(data) {
  return request({
    url: '/sys/users/subMerchantList',
    method: 'post',
    data: data
  })
}

//费率查询
export function getUserRate(data) {
  return request({
    url: '/sys/users/getUserRate',
    method: 'post',
    data: data
  })
}

//电子波流水查询
export function wxhTradingQuery(data) {
  return request({
    url: '/sys/users/wxhTradingQuery',
    method: 'post',
    data: data
  })
}

//获取入金记录数据
export function getInfos(data) {
  return request({
    url: '/sys/order/getInfos',
    method: 'post',
    data: data
  })
}

//获取入金记录数据
export function getEntranceOrderList(data) {
  return request({
    url: '/sys/order/getEntranceOrderList',
    method: 'post',
    data: data
  })
}

//提现订单查询
export function withdrawalOrderQuery(data) {
  return request({
    url: '/sys/users/withdrawalOrderQuery',
    method: 'post',
    data: data
  })
}

//电子回单 
export function electronicReceipt(data) {
  return request({
    url: '/sys/users/electronicReceipt',
    method: 'post',
    data: data
  })
}

//编辑转账方备注
export function userBindingTransEdit(data) {
  return request({
    url: '/sys/users/userBindingTransEdit',
    method: 'post',
    data: data
  })
}

//删除转账方
export function userBindingTransDel(data) {
  return request({
    url: '/sys/users/userBindingTransDel',
    method: 'post',
    data: data
  })
}

//获取转账方列表
export function userBindingTransList(data) {
  return request({
    url: '/sys/users/userBindingTransList',
    method: 'post',
    data: data
  })
}

//转账方绑定
export function userBindingTrans(data) {
  return request({
    url: '/sys/users/userBindingTrans',
    method: 'post',
    data: data
  })
}

//获取协议
export function getContract(id) {
  return request({
    url: '/users/users/Contract/' + id,
    method: 'get'
  })
}

//批量转账订单查询
export function getSelectTransferBathOrder(data) {
  return request({
    url: '/sys/order/getSelectTransferBathOrder',
    method: 'post',
    data: data
  })
}

//单笔转账订单查询
export function getSelectTransferOrder(data) {
  return request({
    url: '/sys/order/getSelectTransferOrder',
    method: 'post',
    data: data
  })
}


//获取验证码
export function getCodes(data) {
  return request({
    url: '/users/users/code',
    method: 'post',
    data: data
  })
}

//子商户列表
export function userList(data) {
  return request({
    url: '/sys/users/userList',
    method: 'post',
    data: data
  })
}

//个体户和企业解绑银行卡
export function relieveBankes(data) {
  return request({
    url: '/sys/users/relieveBanke',
    method: 'post',
    data: data
  })
}

//个体户和企业绑定银行卡
export function addBanks(data) {
  return request({
    url: '/sys/users/addBank',
    method: 'post',
    data: data
  })
}

//企付通联行号列表
export function qftCode(data) {
  return request({
    url: '/sys/users/qftCode',
    method: 'post',
    data: data
  })
}

//进件状态查询
export function registerQuery(data) {
  return request({
    url: '/sys/users/registerQuery',
    method: 'post',
    data: data
  })
}

//企业和个体户进件
export function createRegisterEnterprise(data) {
  return request({
    url: '/sys/users/createRegisterEnterprise',
    method: 'post',
    data: data
  })
}

//提现
export function getMoneys(data) {
  return request({
    url: '/sys/users/getMoney',
    method: 'post',
    data: data
  })
}

//批量转账列表订单-子订单
export function getTransferBathInfoOrder(data) {
  return request({
    url: '/sys/order/getTransferBathInfoOrder',
    method: 'post',
    data: data
  })
}


//批量转账列表订单
export function getTransferBathOrder(data) {
  return request({
    url: '/sys/order/getTransferBathOrder',
    method: 'post',
    data: data
  })
}

//单笔转账列表订单
export function getTransferOrder(data) {
  return request({
    url: '/sys/order/getTransferOrder',
    method: 'post',
    data: data
  })
}

//转账和批量转账订单短信验证码确认
export function transferConfirmPay(data) {
  return request({
    url: '/sys/users/transferConfirmPay',
    method: 'post',
    data: data
  })
}

//获取企付通综合短信
export function getCode(data) {
  return request({
    url: '/sys/users/getCode',
    method: 'post',
    data: data
  })
}

//获取批量转账接收方列表含下级电子薄信息
export function getAccountLists(data) {
  return request({
    url: '/sys/users/getAccountLists',
    method: 'post',
    data: data
  })
}

//批量转账发起请求
export function transferBathAccount(data) {
  return request({
    url: '/sys/users/transferBathAccount',
    method: 'post',
    data: data
  })
}

//单笔转账发起请求
export function transferAccount(data) {
  return request({
    url: '/sys/users/transferAccount',
    method: 'post',
    data: data
  })
}



//提现列表订单
export function getCashOrder(data) {
  return request({
    url: '/sys/order/getCashOrder',
    method: 'post',
    data: data
  })
}

//修改密码
export function updatePasswords(data) {
  return request({
    url: '/sys/users/updatePassword',
    method: 'post',
    data: data
  })
}

//获取银行卡
export function getBankList(data) {
  return request({
    url: '/sys/users/getBankList',
    method: 'post',
    data: data
  })
}

//获取电子薄
export function getAccountList(data) {
  return request({
    url: '/sys/users/getAccountList',
    method: 'post',
    data: data
  })
}
//创建电子薄
export function accountCreate(data) {
  return request({
    url: '/sys/users/accountCreate',
    method: 'post',
    data: data
  })
}

//确认签约
export function getUserSign(data) {
  return request({
    url: '/sys/users/getUserSign',
    method: 'post',
    data: data
  })
}

//获取登陆用户认证信息
export function getUserType(data) {
  return request({
    url: '/sys/users/getUserType',
    method: 'post',
    data: data
  })
}

//个人进件
export function createRegister(data) {
  return request({
    url: '/sys/users/createRegister',
    method: 'post',
    data: data
  })
}

//文件上传返回企付通medeid
export function qftUploadFile(data) {
  return request({
    url: '/sys/upload/newsImage',
    method: 'post',
    data: data
  })
}


//注册接口
export function regedit(data){
	return request({
		url: "/users/users/regedit", 
		method: "post", 
		data: data  
	});
}

//登录接口
export function login(data){
	return request({
		url: "/users/users/login", 
		method: "post", 
		data: data  
	});
}


//、、、、、、、、、、、、、、、、、、、、、、、、、、、、、、






//商户ocr识别
export function merchantOcr(data){
	return request({
		url: "/merchant/ocr", 
		method: "post", 
		data: data  
	});
}

//商户列表分页数据
export function merchantList(data){
	return request({
		url: "/merchant/list", 
		method: "post", 
		data: data  
	});
}
 
//开户行地址编码
export function areaCodeGetDataList(data){
	return request({
		url: "/areaCode/getDataList", 
		method: "post", 
		data: data  
	});
}
 
//商户入网
export function merchantSave(data){
	return request({
		url: "/merchant/save", 
		method: "post", 
		data: data  
	});
}
 
//微信文件上传
export function newsImage(data){
	return request({
		url: "/upload/newsImage", 
		method: "post", 
		data: data  
	});
}
 

 
// get
export function getBanners(data) {
	return request({
		url: "/banner",//这个地址是去掉公共地址和私有域名之后剩下的地址
		method: "GET",//请求方式 支持多种方式  get post put delete 等等
		data//发送请求要配置的参数 无参数的情况下也可以不写
	});
}

//post
export function getBanners2(data){
	return request({
		url: "/t****", 
		method: "post", 
		data: data  
	});
}