import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/homeview/index.vue'
import User from '../views/user/user.vue'
import Home from '../views/home/index.vue'



const routes = [
	 
   {
     path: '/',
     name: 'login',
     component: () => import('../views/login/login.vue')
   },
    {
        path:'/main',
        name:'main',
        component: () => import('../views/main/main.vue'),
        children:[//二级路由
		   {
		       path:'/home',
		       name:'home',
		       component:() => import('../views/home/index.vue')
		   },
		   {
		       path:'/getShopNum',
		       name:'getShopNum',
		       component:() => import('../views/getShopNum/index.vue')
		   },
		   {
		       path:'/objPay',
		       name:'objPay',
		       component:() => import('../views/objPay/index.vue')
		   },
            {
                path:'/orderQuery',
                name:'orderQuery',
                component:() => import('../views/orderQuery/index.vue')
            },
            {
                path:'/payNotifyUrl',
                name:'payNotifyUrl',
                component:() => import('../views/payNotifyUrl/index.vue')
            },
		   {
		       path:'/orderClose',
		       name:'orderClose',
		       component:() => import('../views/orderClose/index.vue')
		   },
		   {
		       path:'/orderUnifyRefund',
		       name:'orderUnifyRefund',
		       component:() => import('../views/orderUnifyRefund/index.vue')
		   },
		   {
		       path:'/orderUnifyRefundQuery',
		       name:'orderUnifyRefundQuery',
		       component:() => import('../views/orderUnifyRefundQuery/index.vue')
		   },
		   {
		       path:'/ledger/openLedger',//开通分账
		       name:'openLedger',
		       component:() => import('../views/ledger/main/openLedger.vue')
		   },
		   {
		       path:'/ledger/ledgerFileCode',//附件上传
		       name:'ledgerFileCode',
		       component:() => import('../views/ledger/main/ledgerFileCode.vue')
		   },
		   {
		       path:'/ledger/ledgerAddUser',//分账接收方创建
		       name:'ledgerAddUser',
		       component:() => import('../views/ledger/main/ledgerAddUser.vue')
		   },
		   {
		       path:'/ledger/cardBin',//卡bin信息查询
		       name:'cardBin',
		       component:() => import('../views/ledger/main/cardBin.vue')
		   },
		   
		   {
		       path:'/ledger/applyBind',//分账关系绑定
		       name:'applyBind',
		       component:() => import('../views/ledger/main/applyBind.vue')
		   },
		   {
		       path:'/ledger/applyUnBind',//分账关系解绑
		       name:'applyUnBind',
		       component:() => import('../views/ledger/main/applyUnBind.vue')
		   },
		   
		   {
		       path:'/ledger/separate',//订单分账
		       name:'separate',
		       component:() => import('../views/ledger/main/separate.vue')
		   },
		   
		   // {
		   //     path:'/ledger/sacsCancel',//订单分账撤销
		   //     name:'sacsCancel',
		   //     component:() => import('../views/ledger/main/sacsCancel.vue')
		   // },
		   // {
		   //     path:'/ledger/sacsFallback',//订单分账回退
		   //     name:'sacsFallback',
		   //     component:() => import('../views/ledger/main/sacsFallback.vue')
		   // },
		   {
		       path:'/ledger/sacsQuery',//订单分账结果查询
		       name:'sacsQuery',
		       component:() => import('../views/ledger/main/sacsQuery.vue')
		   },
		   
		   
		   {
		       path:'/ledger/ewalletWithdraw',//分帐后账户提现
		       name:'ewalletWithdraw',
		       component:() => import('../views/ledger/main/ewalletWithdraw.vue')
		   },
		   {
		       path:'/ledger/ewalletWithdrawQuery',//提现结果查询
		       name:'ewalletWithdrawQuery',
		       component:() => import('../views/ledger/main/ewalletWithdrawQuery.vue')
		   },
		   
		   
		   {
		       path:'/ledger/sacsQueryAmt',//订单可分账金额查询
		       name:'sacsQueryAmt',
		       component:() => import('../views/ledger/main/sacsQueryAmt.vue')
		   },
		   {
		       path:'/ledger/ewalletWithdrawQueryOk',//提现结果通知
		       name:'ewalletWithdrawQueryOk',
		       component:() => import('../views/ledger/main/ewalletWithdrawQueryOk.vue')
		   },
		   
		   {
		       path:'/ledger/ewalletSettleProfile',//提现模式设置
		       name:'ewalletSettleProfile',
		       component:() => import('../views/ledger/main/ewalletSettleProfile.vue')
		   },
		   
		   {
		       path:'/ledger/settleQuery',//提现模式查询
		       name:'settleQuery',
		       component:() => import('../views/ledger/main/settleQuery.vue')
		   },
		   {
		       path:'/ledger/ewalletWithdrawD1',//提现D1
		       name:'ewalletWithdrawD1',
		       component:() => import('../views/ledger/main/ewalletWithdrawD1.vue')
		   },
		   {
		       path:'/ledger/ewalletBalanceQuery',//账户余额查询
		       name:'ewalletBalanceQuery',
		       component:() => import('../views/ledger/main/ewalletBalanceQuery.vue')
		   },
		   {
		       path:'/ledger/queryAcctInfo',//待结算余额查询
		       name:'queryAcctInfo',
		       component:() => import('../views/ledger/main/queryAcctInfo.vue')
		   },
		   {
		       path:'/ledger/transferDirect',//可用余额定向转账
		       name:'transferDirect',
		       component:() => import('../views/ledger/main/transferDirect.vue')
		   },
		   {
		       path:'/ledger/main/authenApi',//黑灰产鉴权
		       name:'transferDirect',
		       component:() => import('../views/ledger/main/authenApi.vue')
		   },
		   // {
		   //      path:'/ledger',
		   //      name:'ledger',  
		   //      children:[
		   // 	     //分账
		   // 	     {
		   // 	         path:'/',
		   // 	         label:'home',
		   // 	         component:() => import('../views/ledger/index.vue')
		   // 	     },
		     
		   //     ]
		   // }
		   
		   // {
		   //     path:'/cashOrder',
		   //     name:'cashOrder',
		   //     component:() => import('../views/order/cashOrder.vue')
		   // },
		   // //转账订单
		   // {
		   //     path:'/transferOrder',
		   //     name:'transferOrder',
		   //     component:() => import('../views/order/transferOrder.vue')
		   // },
		   // //批量转账订单
		   // {
		   //     path:'/bathTransferOrder',
		   //     name:'transferOrder',
		   //     component:() => import('../views/order/bathTransferOrder.vue')
		   // },
		   // //入金订单
		   // {
		   //     path:'/entranceMoney',
		   //     name:'transferOrder',
		   //     component:() => import('../views/order/entranceMoney.vue'),
		   // },
		   // //测试
		   // {
		   //     path:'/test',
		   //     name:'test',
		   //     component:() => import('../views/test/test.vue')
		   // },
		    
        ]
	},
	{
	  path: '/test',
	  name: 'test',
	  component:() => import('../views/test/test.vue')
	},
]
Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
