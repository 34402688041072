export default {
	// 开发环境
	dev: {
		baseUrl: "http://localhost:18081" //开发环境公共地址
	},
	//   测试环境test
	test: {
		baseUrl: "http://localhost:18082" //测试环境公共地址
	},
	//线上接口
	prod: {
		baseUrl: "http://localhost:18083" //线上环境公共地址
	}
};
