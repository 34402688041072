<template>
  <div style="width:100%;">
      <!-- <el-container style="">
        <el-aside style="width: 200px;height: 100%;overflow: scroll;display: block;">
          <CommonAside />
        </el-aside>
		
        <el-container style="height: 100%;overflow: scroll;display: block;">
          
          <el-main>
			  <router-view></router-view>
		  </el-main>
        </el-container>
      </el-container> -->
	  <!-- <el-container style="height: 900px; border: 1px solid #eee;overflow-x: hidden;"> -->
	  <el-container :style="{'height':getClientHeight}" class="div">
	    <el-aside width="200px" style="overflow:hidden;height: 100%;">
	       <CommonAside />
	    </el-aside>
	    
	    <el-container>
	      <el-header style="text-align: right; font-size: 12px;background-color: #fafafa; display: flex;">
			<div style="width:100% ;text-align: center;font-size: 20px;font-weight: 600;justify-content: center;justify-items: auto;">
				易付鸟开放平台-V1.0.2
			</div>
			
	        
	      </el-header>
	      
	      <el-main >
	         <router-view style="overflow-y: hidden;"></router-view>
	      </el-main>
	    </el-container>
	  </el-container>
	
	 
	  
	  <el-dialog style="margin-top:0;" title=" 修 改 密 码 " :visible.sync="initData.updatePasswordOpen" :width="'1000px'">
	  	<el-row style="">
	  		<el-card style="margin-top: -20px;">
	  			 <el-form ref="form"  label-width="150px" style="margin-top: 0px">  
	  			 	<el-form-item label="原始密码" >
	  			 		 <el-input  v-model="initData.userPassword.userPassword"></el-input>
	  			 	</el-form-item>
	  			 	<el-form-item label="修改密码" >
	  			 		 <el-input  v-model="initData.userPassword.userPassword1"></el-input>
	  			 	</el-form-item>
	  			 	<el-form-item label="确认密码" >
	  			 		 <el-input  v-model="initData.userPassword.userPassword2"></el-input>
	  			 	</el-form-item>
	  			 	<el-form-item label="" style="width: 520px;">
	  			 		 <el-button type="primary" @click="submitUserPassword()" > 提 交 修 改 密 码 </el-button>
	  			 	</el-form-item> 
	  			 </el-form>
	  		</el-card>
	  	</el-row>
	  </el-dialog>
	  
    </div> 
</template>
<script>
import { updatePasswords } from "../../http/api"
import CommonAside from "@/components/CommonAside";
export default {
  name: "Main",
  components:{
    CommonAside
  },
  data() {
  	return {
		clientHeight:"",
		userInfo:{},
		initData:{
			updatePasswordOpen:false,
			userPassword:{
				userPassword:"",//用户原始密码
				userPassword1:"",//修改密码1
				userPassword2:"",//确认密码2
			}
		}
	}
  },
  mounted() {
	  var userInfo = localStorage.getItem("userInfo");
	  this.userInfo = JSON.parse(userInfo);
  	
  },
  methods:{
	  //修改密码
	  submitUserPassword(){
		  updatePasswords(this.initData.userPassword).then(res =>{
			  if(res){
				this.$notify({
				  title: '密码小提示',
				  message: '密码修改成功',
				  duration: 0
				});
				this.initData.userPassword = {};
				this.initData.updatePasswordOpen = false;
			  }
		  }) 
	  },
	  //修改密码
	  updatePassword(){
		  this.initData.updatePasswordOpen = true;
	  },
	  //退出登录
	  exitUser(){
		  this.$router.replace('/');
	  }
  },
  computed:{
	  getClientHeight:function () {
	    //屏幕可视区域的高度
	     let clientHeight = document.documentElement.clientHeight + "px"
	     console.log("clientHeight 1=="+clientHeight)
	     //窗口可视区域发生变化的时候执行
	     window.onresize = () => {
	      clientHeight = document.documentElement.clientHeight + "px"
	      console.log("clientHeight changed2=="+clientHeight)
	      return clientHeight
	     }
	     console.log("clientHeight 3=="+clientHeight)
	     return clientHeight
	    }
  }
}
</script>
<style lang="scss">
.div{border: 1px solid #eee;overflow-x: hidden;}
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }
html,body,h3{
  margin: 0;
  padding: 0;
}

</style>
