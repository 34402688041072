<template>
	<div style="margin-left: 10px;">
		<el-row>
			<el-card class="box-card" style="">
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item :to="{ path: '/home' }">接口接入介绍</el-breadcrumb-item>
				  <el-breadcrumb-item>接口接入介绍</el-breadcrumb-item>
				</el-breadcrumb>
			</el-card> 
		</el-row>
		
		<el-row>
			<el-card class="box-card" style="margin-top: 10px;">
				<el-descriptions title="接口接入流程" direction="horizontal" :column="1" border labelStyle="width: 50px;">
				  <el-descriptions-item label="1">平台分配接入方partnerId、业务数据AES 加密所需的对称密钥、平台api 公钥，并发送至接入方。</el-descriptions-item>
				  <el-descriptions-item label="2" contentStyle="color: red;" style="">接入方生成RSA 2048 公私钥对，将公钥发至平台方。</el-descriptions-item>
				  <el-descriptions-item label="3">接入方partnerId（对接群里提供）</el-descriptions-item>
				  <el-descriptions-item label="4">data 加解密AES 密钥】（对接群里提供）</el-descriptions-item>
				  <el-descriptions-item label="5">接入方公钥（由接入方自行生成）</el-descriptions-item>
				  <el-descriptions-item label="6">接入方私钥（由接入方自行生成）</el-descriptions-item>
				  <el-descriptions-item label="7">平台公钥（对接群里运营提供）</el-descriptions-item>
				</el-descriptions>
			</el-card>
			
			<el-card class="box-card" style="margin-top: 10px;">
				<el-descriptions title="API接口参数加解密说明" direction="horizontal" :column="1" border labelStyle="width: 50px;">
				  <el-descriptions-item label="1">API 接口统一采用https 协议POST 请求，JSON 方式进行交互。 </el-descriptions-item>
				  <el-descriptions-item label="2">加密方式，使用AES/CBC/PKCS5Padding, 初始向量值为1122334455667788，对每个业务接口请求参数json 字符串加密后作为请求data 值。</el-descriptions-item>
				</el-descriptions>
			</el-card>
			
			<el-card class="box-card" style="margin-top: 10px;" >
				<el-descriptions title="API加密方式" direction="horizontal" :column="1" border labelStyle="width: 50px;">
				  <el-descriptions-item label="1"  style=""> 加密 data = Base64_encode(aes 加密(业务参数json, 对称密钥)) 。 </el-descriptions-item>
				  <el-descriptions-item label="2"> 解密 业务参数json = Base64_decode(aes 解密(密文data, 对称密钥)) 。</el-descriptions-item>
				</el-descriptions>
			</el-card>
			
			<el-card class="box-card" style="margin-top: 10px;"> 
				<el-descriptions title="API加签方式" direction="horizontal" :column="1" border labelStyle="width: 50px;">
				  <el-descriptions-item label="1"> 设所有发送或者接收到的数据为集合M； 。 </el-descriptions-item>
				  <el-descriptions-item label="2"> 将集合M 内非空参数值的参数按照参数名ASCII码从小到大排序（字典序）； 。</el-descriptions-item>
				  <el-descriptions-item label="3"> 使用URL键值对的格式（即key1=value1&key2=value2…）拼接成字符串stringA；原始串中，字段名和字段值都采用原始值，不进行URL Encode。 。</el-descriptions-item>
				  <el-descriptions-item label="4"> 使用私钥对stringA 进行SHA256-RSA 签名算法，生成的Base64 结果作为sign。 。</el-descriptions-item> 
				</el-descriptions>
				<el-alert style="margin-top: 10px;"
				   title="基于非对称密钥的SHA256-RSA 数字签名算法。"
				   type="info"
				   :closable="false">
				 </el-alert>
			</el-card>
			
			<el-card class="box-card" style="margin-top: 10px;">
				<el-descriptions title="签名 和 验签说明" direction="horizontal" :column="1" border labelStyle="width: 50px;"> 
				</el-descriptions>
				<el-descriptions title="签名流程" direction="horizontal" :column="1" border labelStyle="width: 50px;">
				  <el-descriptions-item label="1">组装业务参数json 字符串并使用上述加密方式加密，得到密文A</el-descriptions-item>
				  <el-descriptions-item label="2">按加签方式组装得到待签名原文S’</el-descriptions-item>
				  <el-descriptions-item label="3"> 以请求参数为例，得到签名原文为：data=密文A&nonce=随机数&partnerId=TEST0000000001 & timestamp=1659324067881</el-descriptions-item>
				  <el-descriptions-item label="4">以响应参数为例，得到签名原文为：</el-descriptions-item>
				  <el-descriptions-item label="5">错误：code=5001&msg=无效商户信息timestamp=1659597261556，</el-descriptions-item>
				  <el-descriptions-item label="6">成功：code=10000&data=密文业务参数&msg=ok & timestamp=1659597916431</el-descriptions-item>
				  <el-descriptions-item label="7">使用私钥按照加签方式对签名原文S’签名，得到最终签名值S</el-descriptions-item>
				  <el-descriptions-item label="8">请求时携带签名值S</el-descriptions-item>
				</el-descriptions>
				<el-descriptions title="验签流程" direction="horizontal" :column="1" border style="margin-top: 20px;" labelStyle="width: 50px;">
				  <el-descriptions-item label="1">从响应结果中获取签名值S</el-descriptions-item>
				  <el-descriptions-item label="2">按加签方式组装得到待签名原文S’</el-descriptions-item>
				  <el-descriptions-item label="3">以请求参数为例，得到签名原文：data=密文A&nonce=随机数&partnerId=TEST0000000001 & timestamp=1659324067881</el-descriptions-item>
				  <el-descriptions-item label="4">使用平台公钥按照加签方式对签名原文S’和签名值S，进行验签，验签成功即为我方平台签发的数据</el-descriptions-item>
				  <el-descriptions-item label="5">使用加密方式对data 值密文A 进行解密，获取业务数据</el-descriptions-item> 
				</el-descriptions>
			</el-card>
			
			<el-card class="box-card" style="margin-top: 10px;">
				<el-descriptions title="【java版】示例 仅供参考" direction="horizontal" :column="1" border labelStyle="width: 50px;">
				</el-descriptions>
				<el-collapse v-model="activeNames" @change="handleChange">
				  <el-collapse-item title="加密算法【java版】示例 仅供参考" name="1">
				    <div>以比较知名的java hutool 工具包为例：</div>
				    <pre>
		/**
		* aes 加密
		*
		* @param data 待加密数据
		* @param key 密钥key
		* @return
		*/
		public static String encryptData(String data, String key) {
			AES aes = new AES(Mode.CBC, Padding.PKCS5Padding, key.getBytes(StandardCharsets.UTF_8),
			AES_IV.getBytes(StandardCharsets.UTF_8));
			return aes.encryptBase64(data, StandardCharsets.UTF_8);
		}
					</pre>
					
				  </el-collapse-item>
				  <el-collapse-item title="解密算法【java版】示例 仅供参考" name="2">
				  	<div>以比较知名的java hutool 工具包为例：</div>
				  	<pre>
		/**
		* aes 解密
		*
		* @param data 待解密数据
		* @param key 密钥key
		* @return
		*/
		public static String decryptData(String data, String key) {
			AES aes = new AES(Mode.CBC, Padding.PKCS5Padding, key.getBytes(StandardCharsets.UTF_8),
			AES_IV.getBytes(StandardCharsets.UTF_8));
			return aes.decryptStr(data);
		}		
				  	</pre>
				  </el-collapse-item>
				  <el-collapse-item title="签名验签算法【java版】示例 仅供参考" name="3">
				    <div>以比较知名的java hutool 工具包为例：</div>
					<pre>
		签名：
			String s = "待签名字符串";
			Sign sign = SecureUtil.sign(SignAlgorithm.SHA256withRSA, privateKeyBase64, null);
			String signBase64Str = Base64.encode(sign.sign(StrUtil.bytes(s)));
		验签：
			String s = "待签名字符串";
			String signBase64Str = "签名值";
			Sign sign = SecureUtil.sign(SignAlgorithm.SHA256withRSA, null, publicKeyBase64);
			boolean ok = sign.verify(StrUtil.bytes(s), Base64.decode(signBase64Str));
		
		注意:平台给服务商回调时，验签时一定要把回调给的request中的参数全部取出 
		(为空字符串的参数不参与验签）再进行验签。不可通过文档中罗列的参数列表来取，
		因为如果后续回调接口新增参数时，会导致以前的验签失效!
					</pre>
				  </el-collapse-item>
				  <el-collapse-item title="公私钥生成【java版】示例  仅供参考" name="4">
				    <div>以比较知名的java hutool 工具包为例：</div>
					<pre>
		KeyPair pair = SecureUtil.generateKeyPair("RSA", 2048);
		System.out.println("pri:" + Base64.encode(pair.getPrivate().getEncoded()));
		System.out.println("pub:" + Base64.encode(pair.getPublic().getEncoded()));
					</pre>
				  </el-collapse-item>
				   
				</el-collapse>
				
			</el-card>
			
		</el-row>
		 
		
	</div>
</template> 

<script>
import { accountCreate,getAccountList,getBankList,transferAccount,getAccountLists,transferBathAccount,getCode,transferConfirmPay,getMoneys,addBanks,qftCode,relieveBankes,userBindingTrans,userBindingTransList,userBindingTransDel,userBindingTransEdit,getContract,getInfos,wxhTradingQuery,getUserRate,subMerchantList,getUserCashConfigInsert,getUserCashConfig} from "../../http/api"
export default {
  name: "home",
    data(){
      return{
		 activeNames:[]
		
      } 
	},
	created() { 
		 
	},
	mounted() {
		document.title = "易付鸟开放平台"
	},
    methods:{
		 
    }
};
</script>

<style>
</style>