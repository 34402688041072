<template>
	<div > 
	<!-- =={{this.bodyData.userData.accountStatus}}== -->
		<el-row>
			<el-card class="box-card">
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
				  <el-breadcrumb-item>商户入网</el-breadcrumb-item>
				</el-breadcrumb>
			</el-card> 
		</el-row>
		<div style="width: 80%;margin: auto;">
			<el-form ref="form" :rules="rules" :model="form" label-width="150px" style="margin-top: 0px">
				<div v-if=" 0 == active">
					<el-card class="box-card" style="margin-top: 10px;font-size: 24px;display: flex;justify-content: center;">
						<b>入网信息</b>
					</el-card>  
					<!-- 个人入网信息展示 -->
					<el-row style="margin-top: 10px;" v-if="bodyData.userOpen">
						<el-card class="box-card"> 
							<el-row :span="24"> 
								<el-descriptions title="签约认证信息"  :column="2" border labelStyle="text-align: right;width:150px">
								  <template slot="extra">
										<el-button type="primary" size="small" @click="refresh()">刷新审核状态</el-button>
								  </template>
								  <el-descriptions-item label="主体类型"  style="">{{getUserAuthType(bodyData.userAuthType)}}</el-descriptions-item>
								  <el-descriptions-item label="姓名">{{bodyData.userData.usersName}}</el-descriptions-item>
								  <el-descriptions-item label="认证状态">
									  <span type="danger">{{getAccountStatus(bodyData.userData.accountStatus)}}</span>
									  <el-button v-if="'3' == bodyData.userData.accountStatus" type="primary" @click="sign()" style="margin-left: 20px;" size="mini">
										  点我确认签约
									  </el-button>
								  </el-descriptions-item>
								  <el-descriptions-item label="提交时间">{{getData(bodyData.userData.createTime)}}</el-descriptions-item>
								  <el-descriptions-item label="更新时间">{{getData(bodyData.userData.updateTime)}}</el-descriptions-item>
								  <el-descriptions-item label="操作">
									<el-button type="info" @click="updateUserData()" size="mini">查看主体资料</el-button>
								  </el-descriptions-item>
								</el-descriptions>
							</el-row>
						</el-card>
					</el-row>
					<!-- 个体户和企业）信息展示 -->
					<!-- =={{bodyData.companyData.legalMobile}}== -->
					<el-row style="margin-top: 10px;" v-if="bodyData.companyOpen">
						<el-card class="box-card"> 
							<el-row :span="24">
								<el-descriptions :title="'签约认证信息【订单号】'+bodyData.companyData.orderId"  :column="2" border labelStyle="text-align: right;width:150px">
								  <template slot="extra">
										<el-button type="primary" size="small" @click="refresh()">刷新审核状态</el-button>
								  </template>
								  <el-descriptions-item label="主体类型"  style="">{{getUserAuthType(bodyData.userAuthType)}}</el-descriptions-item>
								  <el-descriptions-item label="执照名称">{{bodyData.companyData.licenseName}}</el-descriptions-item>
								  <el-descriptions-item label="认证状态">
									  <span  type="">{{getAccountStatus(bodyData.companyData.accountStatus)}}</span>
									  <el-button v-if="'3' == bodyData.companyData.accountStatus" type="primary" @click="sign()" style="margin-left: 20px;" size="mini">点我确认签约</el-button>
								  </el-descriptions-item>
								  <el-descriptions-item label="提交时间">{{getData(bodyData.companyData.createTime)}}</el-descriptions-item>
								  <el-descriptions-item label="更新时间">{{getData(bodyData.companyData.updateTime)}}</el-descriptions-item>
								  
								  <el-descriptions-item label="操作"> 
									<el-button type="info" @click="updateUserData()" size="mini">查看主体资料</el-button>
								  </el-descriptions-item>
								  
								</el-descriptions>
							</el-row>
						</el-card>
					</el-row> 
					 <!-- =={{paramsData.userParams}}==
					<br/>
					=={{initData.id_card}}== -->
					<br/>
					<!-- =={{initData.initUserData.data}}== -->
					<!-- 入网表单 -->
					<el-row style="margin-top: 10px;" v-if="bodyData.formOpen">
						<el-card class="box-card"> 
						<el-row :span="24">
							<el-col v-if="bodyData.authDataOpen">
							   <el-form-item label="主体类型" prop="subjectType" >
									<el-select style="width: 370px;" v-model="paramsData.dataType" @change="getSubjectType(paramsData.dataType)" placeholder="请选择主体类型">
										<el-option  label="个人用户" value="0"></el-option>
										<el-option  label="个体用户" value="2"></el-option>
										<el-option  label="企业用户" value="3"></el-option>
									</el-select>
							   </el-form-item> 
							</el-col>
							
							 
							<!-- 个体入网 和 企业入网 -->
							<div v-if="'3' == paramsData.dataType || '2' == paramsData.dataType">
								<el-col :span="24">
									<el-divider>基础信息</el-divider>
								</el-col>
								<el-col :span="24">
								   <el-form-item label="商户简称" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.shortName" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="营业执照编号" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.licenseNo" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="营业执照公司名称" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.licenseName" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="营业执照注册地址" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.licenseCompanyAddress" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24" >
								   <el-form-item label="营业执照开始时间">
										 <div class="block" style="width: 1000px;">
											 <el-date-picker style="width: 370px;"
											   format="yyyy-MM-dd" value-format="yyyy-MM-dd"
											   clearable
											   v-model="initData.license_valid.license_valid_date_start"
											   type="date"
											   start-placeholder="开始日期"
											   >
											 </el-date-picker>
										   </div>
								   </el-form-item> 
								</el-col>
								<el-col :span="24" style="">
								   <el-form-item label="营业执照结束时间">
									<div class="block" style="width: 1000px;">
										<el-date-picker style="width: 300px;"
										   v-if="!initData.license_valid.license_valid_date"
										   format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										   clearable
										   v-model="initData.license_valid.license_valid_date_end"
										   type="date"
										   start-placeholder="结束日期"
										   >
										 </el-date-picker>
										 <el-checkbox v-model="initData.license_valid.license_valid_date" style="margin-left: 20px;">长期</el-checkbox>
										</div>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="营业执照照片">
										<el-upload
										  data="yingyezhizhao"
										  :http-request="httpRequestFile"
										  list-type="picture-card"
										  :on-preview="handlePictureCardPreview"
										  :on-remove="handleRemoveYingyezhizhao"
										  :file-list="paramsData.companyParams.file.licenseList"
										  :limit="1"
										  >
										  <i class="el-icon-plus"></i>
										</el-upload>
										<el-dialog :visible.sync="dialogVisible">
										  <img width="100%" :src="dialogImageUrl" alt="">
										</el-dialog>
								   </el-form-item> 
								</el-col>
								 
								<el-col :span="24">
									<el-divider>银行信息</el-divider>
								</el-col>
								<el-col :span="24">
								   <el-form-item label="银行卡账户类型" style="width: 520px;">
										 <el-select style="width: 370px;" v-model="paramsData.companyParams.bankcardType" placeholder="请选择银行卡账户类型">
											<el-option  label="对公银行账户" value="2"></el-option>
											<el-option  label="对私银行账户" v-if="'2'==paramsData.dataType" value="1"></el-option> 
										 </el-select>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="银行卡卡号 " style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.bankAccount" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="开户名称  " style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.bankAccountName" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="银行编码(对公必填)" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.bankCode" clearable></el-input>
										 <el-button size="mini" type="info" @click="getCode()">编码查询</el-button>
								   </el-form-item>
								   
								</el-col>
								<el-col :span="24">
								   <el-form-item label="银联号(对公必填)" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.cnapsCode" clearable></el-input>
										 <el-button size="mini" type="info" @click="getCode()">编码查询</el-button>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
									<el-divider>法人信息</el-divider>
								</el-col>
								<el-col :span="24">
								   <el-form-item label="法人姓名" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.legalName" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="法人证件类型" style="width: 520px;">
										 <el-select style="width: 370px;" v-model="paramsData.companyParams.legalIdcardType"  placeholder="请选择法人证件类型">
											<el-option  label="中国大陆居民- 身份证" value="1"></el-option> 
										 </el-select>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="法人证件号" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.legalIdcard" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="证件正面照">
										<el-upload
										  data="zhengjianzhengmianzhao"
										  :http-request="httpRequestFile"
										  list-type="picture-card"
										  :on-preview="handlePictureCardPreview"
										  :on-remove="handleRemoveZhengJianZhaoZhengMian"
										  :file-list="paramsData.companyParams.file.idCardFrontList"
										  :limit="1"
										  >
										  <i class="el-icon-plus"></i>
										</el-upload>
										<el-dialog :visible.sync="dialogVisible">
										  <img width="100%" :src="dialogImageUrl" alt="">
										</el-dialog>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="证件反面照">
										<el-upload
										  data="zhengjianfanmianzhao"
										  :http-request="httpRequestFile"
										  list-type="picture-card"
										  :on-preview="handlePictureCardPreview"
										  :on-remove="handleRemoveZhengJianZhaoFanMian"
										  :file-list="paramsData.companyParams.file.idCardBackList"
										  :limit="1"
										  >
										  <i class="el-icon-plus"></i>
										</el-upload>
										<el-dialog :visible.sync="dialogVisible">
										  <img width="100%" :src="dialogImageUrl" alt="">
										</el-dialog>
								   </el-form-item> 
								</el-col>
								<el-col :span="24" >
								   <el-form-item label="证件有效期开始时间">
										 <div class="block" style="width: 1000px;">
											 <el-date-picker style="width: 370px;"
											   format="yyyy-MM-dd" value-format="yyyy-MM-dd"
											   clearable
											   v-model="initData.legal_idcard_valid.legal_idcard_valid_date_start"
											   type="date"
											   start-placeholder="开始日期"
											   >
											 </el-date-picker>
										   </div>
								   </el-form-item> 
								</el-col>
								<el-col :span="24" style="">
								   <el-form-item label="证件有效期结束时间">
									<div class="block" style="width: 1000px;">
										<el-date-picker style="width: 300px;"
										   v-if="!initData.legal_idcard_valid.legal_idcard_valid_date"
										   format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										   clearable
										   v-model="initData.legal_idcard_valid.legal_idcard_valid_date_end"
										   type="date"
										   start-placeholder="结束日期"
										   >
										 </el-date-picker>
										 <el-checkbox v-model="initData.legal_idcard_valid.legal_idcard_valid_date" style="margin-left: 20px;">长期</el-checkbox>
										</div>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="法人手机号" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.legalMobile" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="法人邮箱号" style="width: 520px;">
										 <el-input v-model="paramsData.companyParams.contactEmail" clearable></el-input>
								   </el-form-item> 
								</el-col>
								
							</div>
							<!-- 个人入网 -->
							<div v-if="'0' == paramsData.dataType">
								<el-col :span="24">
									<el-divider>基础信息</el-divider>
								</el-col>
								<el-col :span="24" v-if="bodyData.isUpdateData.user.usersName">
								   <el-form-item label="姓名" style="width: 520px;">
										 <el-input v-model="paramsData.userParams.usersName" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24" v-if="bodyData.isUpdateData.user.idCard">
								   <el-form-item label="身份证号码" style="width: 520px;">
										 <el-input v-model="paramsData.userParams.idCard" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="手机号" style="width: 520px;">
										 <el-input v-model="paramsData.userParams.mobile" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="身份证正面">
										<el-upload
										  data="shenfenzhengzhengmian"
										  :http-request="httpRequestFile"
										  list-type="picture-card"
										  :on-preview="handlePictureCardPreview"
										  :on-remove="handleRemoveShenfenzhengzhengmian"
										  :file-list="paramsData.userParams.file.idCardFrontList"
										  :limit="1"
										  >
										  <i class="el-icon-plus"></i>
										</el-upload>
										<el-dialog :visible.sync="dialogVisible">
										  <img width="100%" :src="dialogImageUrl" alt="">
										</el-dialog>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
									
								   <el-form-item label="身份证反面">
										<el-upload
										  data="shenfenzhengfanmian"
										  :http-request="httpRequestFile"
										  list-type="picture-card"
										  :on-preview="handlePictureCardPreview"
										  :on-remove="handleRemoveShenfenzhengFanmian"
										  :file-list="paramsData.userParams.file.idCardBackList"
										  :limit="1"
										  >
										  <i class="el-icon-plus"></i>
										</el-upload>
										<el-dialog :visible.sync="dialogVisible">
										  <img width="100%" :src="dialogImageUrl" alt="">
										</el-dialog>
								   </el-form-item> 
								</el-col>
								<el-col :span="24" >
								   <el-form-item label="身份证开始时间">
										 <div class="block" style="width: 1000px;">
											 <el-date-picker style="width: 370px;"
											   format="yyyy-MM-dd" value-format="yyyy-MM-dd"
											   clearable
											   v-model="initData.id_card.id_card_valid_date_start"
											   type="date"
											   start-placeholder="开始日期"
											   >
											 </el-date-picker>
										   </div>
								   </el-form-item> 
								</el-col>
								<el-col :span="24" style="">
								   <el-form-item label="身份证到期时间">
									<div class="block" style="width: 1000px;">
										<el-date-picker style="width: 300px;"
										   v-if="!initData.id_card.id_card_valid_date"
										   format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										   clearable
										   v-model="initData.id_card.id_card_valid_date_end"
										   type="date"
										   start-placeholder="结束日期"
										   >
										 </el-date-picker>
										 <el-checkbox v-model="initData.id_card.id_card_valid_date" style="margin-left: 20px;">长期</el-checkbox>
										</div>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
									<el-divider>银行信息</el-divider>
								</el-col>
								<el-col :span="24">
								   <el-form-item label="银行卡号" style="width: 520px;">
										 <el-input v-model="paramsData.userParams.bankAccount" clearable></el-input>
								   </el-form-item> 
								</el-col>
								
								<el-col :span="24">
								   <el-form-item label="开户名称" style="width: 520px;">
										 <el-input v-model="paramsData.userParams.bankAccountName" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="银行编码(选填)" style="width: 520px;">
										 <el-input v-model="paramsData.userParams.bankCode" clearable></el-input>
								   </el-form-item> 
								</el-col>
								<el-col :span="24">
								   <el-form-item label="银联号(选填)" style="width: 520px;">
										 <el-input v-model="paramsData.userParams.cnapsCode" clearable></el-input>
								   </el-form-item> 
								</el-col>
							</div> 
							</el-row>
						</el-card>
					</el-row>
				</div>
				<el-row style="margin-top: 10px;" v-if="bodyData.formSubmitOpen">
					<el-col :span="24">
					  <el-card class="box-card">
						  <div style="margin: auto;width: 900px;"> 
							  <el-button type="primary" @click="submit()" style="width: 700px;">提交</el-button> 
						  </div>
					  </el-card>
					</el-col>
				</el-row>
			</el-form>
			<!-- =={{paramsData.userParams}}== -->
			<!-- 确认后输入验证码确认 -->
			<el-dialog
			  title="开户申请确认"
			  :visible.sync="dialogVisibleCreateUser"
			  width="30%"
			  :before-close="handleClose"> 
			   <!-- =={{initData.signData}}== -->
			  <el-form :model="form">
				  <el-form-item label="接收手机号码" label-width="100px">
					<span>{{initData.signData.mobile}}</span>
				  </el-form-item>
				  <el-form-item label="短信验证码" label-width="100px">
					<el-input v-model="initData.signData.verifyCode" autocomplete="off"></el-input>
				  </el-form-item>
				  <div style="display: flex;justify-content: left;">
						  短信验证码已下发成功...
				  </div> 
			  </el-form>
			  <span slot="footer" class="dialog-footer">
				<el-button type="text" @click="getCodes()">重新获取验证码</el-button>
				<!-- <el-button @click="handleClose">取 消</el-button> -->
				<el-button type="primary" @click="signHttp()">确 定</el-button>
			  </span>
			</el-dialog>
			<!-- =={{paramsData.companyParams.file}}== -->
			
			<el-dialog style="margin-top:0;" title="联行号和银行编码" :visible.sync="code.codeOpen" :width="'1200px'">
			<el-row style="">
				<el-card style="margin-top: -20px;">
					<el-form ref="form" :model="form" label-width="100px" size="mini">
					   <el-row >  
						 <el-col :span="6">
						 	<el-form-item label="银联号">
						 	     <el-input v-model="code.params.bankCode" clearable></el-input>
						 	</el-form-item>
						 </el-col>
						 <el-col :span="8">
						 	<el-form-item label="*银行全称*">
						 	     <el-input v-model="code.params.bankName" clearable></el-input>
						 	</el-form-item>
						 </el-col>
						 <el-col :span="6">
						 	<el-form-item label="银行编码">
						 	     <el-input v-model="code.params.drecCode" clearable></el-input>
						 	</el-form-item>
						 </el-col>
						  
						 <el-col :span="4">
						 	<el-button type="primary" @click="getQftCode()" style="margin-left: 10px;" size="mini">
								查询
							</el-button>
						 </el-col>	
					   </el-row>
					</el-form>
				</el-card>
				<el-card style="margin-top: 10px;">
					<el-table :data="code.codeList" style="width: 100%" :size="mini" border="">
					      <el-table-column prop="bankCode" label="联行号"  width="150"></el-table-column>
					      <el-table-column prop="bankName" label="银行全称"></el-table-column>
						  <el-table-column prop="drecCode" label="银行编码"  width="150"></el-table-column>
						  <el-table-column prop="tel" label="银行电话" width="250"></el-table-column>
						  <el-table-column prop="tel" label="操作" width="100">
							  <template slot-scope="scope">
								  <el-button type="primary" size="small" @click="handletText(scope.row)">
								  	回填数据
								  </el-button>
							  </template>
						  </el-table-column>
					</el-table>
					<div class="block" style="width: 100%;height: 50px;text-align: right;margin-top: 10px;">
					    <el-pagination
					      @size-change="handleSizeChange"
					      @current-change="handleCurrentChange"
					      :current-page="code.params.currentPage"
					      :page-sizes="[5,10,15,20,25,30,35,40,45,50,100, 200, 300, 400,1000,5000]"
					      :page-size="code.params.pageSize"
					      layout="total, sizes, prev, pager, next, jumper"
					      :total="code.total">
					    </el-pagination>
					</div>	
				</el-card> 
			</el-row>
			</el-dialog>
		</div>
	</div>
</template>
<script>
import { getUserSign,getUserType,createRegister,qftUploadFile,createRegisterEnterprise,getCode,registerQuery,qftCode } from "@/http/api" 
export default {
  props:{
	merchantData:Object
  },
  data() {
    return {
		code:{
			codeOpen:false,
			codeList:[],
			total:0,
			params:{
				bankCode:"",//银联号
				bankName:"",//银行全称
				drecCode:"",//银行编码
				pageNo:1,
				pageSize:5,
				currentPage:1
			}
		},
		//页面控制
		bodyData:{
			//用户认证后的表头
			userOpen:false,
			//用户认证的数据
			userData:{},
			//企业认证后的表头
			companyOpen:false,
			//企业认证后的数据
			companyData:{},
			//form表单
			formOpen:false,
			//认证主体类型控制
			authDataOpen:false,
			//用户认证类型   认证类型：0个人，3企业，2个体户
			userAuthType:"",
			//不可更新的字段
			isUpdateData:{
				user:{
					usersName:true,//用户名不可修改
					idCard:true,   //身份证号码
				}
			},
			formSubmitOpen:false//提交按钮
		},
		//提交开户后的响应数据
		resData:{
			"order_date":"",
			"order_id":"",
		},
		//开户申请确认弹框开关
		dialogVisibleCreateUser: false,
		initData:{
			//开户申请确认数据包--最终签约数据
			signData:{
				id:"",//签约的数据id
				mobile:"",//手机号 
				verifyCode:"",//签约验证码
				userAuthType:"",//签约类型
			},
			
			id_card:{//身份证有效期
				id_card_valid_date_start:"",//身份证开始时间
				id_card_valid_date_end:"",  //身份证到期时间
				id_card_valid_date:false    // 默认不选中
			},
			license_valid:{//营业执照有效期
				license_valid_date_start:"",//营业执照开始时间
				license_valid_date_end:"",//营业执照结束时间
				license_valid_date:false  //默认不选中
			},
			legal_idcard_valid:{//证件有效期
				legal_idcard_valid_date_start:"",//证件有效期开始
				legal_idcard_valid_date_end:"",//证件有效期结束
				legal_idcard_valid_date:false//默认不选中
			},
			//获取用户初始化信息
			initUserData:{
				"user_auth_type":"",
				"user_data_id":"",
				"data":{}
			},
			//表头开关
			dataTitle:false,
			//入网表单开关
			userData:false,
			//主体类型开关 修改不支持修改这个
			org_type:false,
		},
		//入网数据包
		paramsData:{
			dataType:"0",//主体类型：0个人，2个体，3是企业  
			//个人入网数据包
			userParams:{
				usersName:"",//姓名
				idCard:"",//身份证号 
				mobile:"",//手机号 
				idCardFrontMediaId:"",//身份证正面图片
				idCardBackMediaId:"",//身份证反面图片 
				idCardValidDate:"",//若证件有效期
				bankAccount:"",//银行卡卡号 
				bankAccountName:"",//开户名称 
				bankCode:"",//银行编码
				cnapsCode:"",//联行号
				file:{
					idCardFrontList:[],//身份证正面
					idCardBackList:[]//身份证反面
				}
			},
			//企业入网数据包
			companyParams:{
				orgType:"2",//主体类型（主体类型：0个人，2个体，3是企业  ）
				shortName:"",//商户简称
				licenseNo:"",//营业执照编号
				licenseName:"",//营业执照公司名称
				licenseCompanyAddress:"",//营业执照注册地址
				licenseValidDate:"",//营业期限
				licenseMediaId:"",//营业执照照片 
				bankcardType:"",//银行卡账户类型
				bankAccount:"",//银行卡卡号
				bankAccountName:"",//开户名称
				bankCode:"",//银行编码
				cnapsCode:"",//银联号
				legalName:"",//法人姓名
				legalIdcard:"",//法人证件号
				legalIdcardType:"",//法人证件类型
				idCardFrontMediaId:"",//证件正面mediaid
				idCardBackMediaId:"",//证件反面mediaid
				legalIdcardValidDate:"",//证件有效期
				legalMobile:"",//法人手机号码
				contactEmail:"",//法人邮箱地址
				file:{
					licenseList:[],//营业执照照片
					idCardFrontList:[],//证件照正面
					idCardBackList:[],//证件照反面
				}
			}
		},
		//图片放大需要
		dialogVisible:false,
		dialogImageUrl:"",
		active:0,  
		//账户类型 
        weChatUserData:{ 
		}, 
		//api: "http://127.0.0.1:18888/ywyPay-api"+"/upload/newsImage?satoken=3ec74a52-083d-47a1-b885-518c94f93d5b",
    };
  },
  created() {
	  // alert(localStorage.getItem("Authorization"))
	  window.localStorage.setItem("Authorization",window.localStorage.getItem("Authorization"));
	  window.localStorage.setItem("userInfo",window.localStorage.getItem("userInfo")); 
	  this.init();//初始化
  },
  methods: {
	//选中银联号和银行编码
	handletText(row){
		var drecCode = row.drecCode;//银行编码
		var bankCode = row.bankCode;//银联号 
		//个人赋值
		this.paramsData.userParams.cnapsCode = bankCode;
		this.paramsData.userParams.bankCode = drecCode;
		//企业赋值
		this.paramsData.companyParams.cnapsCode = bankCode;
		this.paramsData.companyParams.bankCode = drecCode;
		this.code.codeOpen = false;
		this.$message('银行编码和银联号已粘贴成功!');	
	},
	handleSizeChange(val) {
	  this.code.params.pageSize = val;
	  this.getCode();
	},
	handleCurrentChange(val) {
	  this.code.params.pageNo = val;
	  this.getCode();
	},
	//编码查询触发
	getCode(){
		this.code.codeOpen = true;
		this.getQftCode();
	},
	getQftCode(){
		qftCode(this.code.params).then(res =>{
			this.code.total = res.list.total;
			this.code.codeList = res.list.records;
		})
	},
	//刷新状态
	refresh(){
		this.$message('刷新成功!');	
		var params = {}
		registerQuery(params).then(res =>{
			if("" == res.data){
				this.getUserTypeClick();
			}else{
				this.$message(res.data);	
				this.getUserTypeClick();
			}
		})
	},
	//签约获取短信
	getCodes(){
		var orderId = "";
		var userAuthType = this.bodyData.userAuthType;
		if("0" == userAuthType){
			orderId = this.bodyData.userData.orderId;
		}else if("2" == userAuthType){
			orderId = this.bodyData.companyData.orderId;
		}else if("3" == userAuthType){
			orderId = this.bodyData.companyData.orderId;
		}else{
			alert("功能未开放1");
		}
		
		var params = {
			orderId:orderId,
			smsType:"2",//业务类型1-转账短信验证，2-进件短信验证，3-授权短信验证
		}
		getCode(params).then(res =>{
			if(""==res.data){
				this.$message('验证码发送成功!');	
			}
		})
	},
	getData(n){
		n=new Date(n)
		return n.toLocaleDateString().replace(/\//g, "-") + " " + n.toTimeString().substr(0, 8)
	},
	//提交开户数据
	submit(){
		var dataType = this.paramsData.dataType;
		
		if("3" == dataType || "2" == dataType){
			//个体户和企业数据提交
			this.paramsData.companyParams.orgType = dataType;//赋值主体类型  后台校验相关信息
			var companyParams = this.paramsData.companyParams;
			if(1 != this.paramsData.companyParams.file.licenseList.length){
				this.$message.error('请上传(营业执照)照片！');
				return;
			}
			             
			if(1 != this.paramsData.companyParams.file.idCardFrontList.length){
				this.$message.error('请上传(证件照正面~人像面~照片)照片！');
				return;
			}
			if(1 != this.paramsData.companyParams.file.idCardBackList.length){
				this.$message.error('请上传(证件照反面~信息面~照片)照片！');
				return;
			}
			
			 
			//营业执照日期校验
			var license_valid_date_start = this.initData.license_valid.license_valid_date_start;//营业执照开始时间
			var license_valid_date_end =   this.initData.license_valid.license_valid_date_end;  //营业执照结束时间
			var license_valid_date = this.initData.license_valid.license_valid_date;  //营业执照结束时间是否长期  false不是长期  true是长期
			if("" == license_valid_date_start){
				this.$message.error('请选择身份证开始时间！');
				return;
			}
			if(JSON.parse(license_valid_date)){
				//营业执照是长期
				this.paramsData.companyParams.licenseValidDate = license_valid_date_start+",长期";
			}else{
				//营业执照不是长期
				if("" == license_valid_date_end || null == license_valid_date_end){
					this.$message.error('请选择营业执照结束时间！');
					return;
				}else{
					this.paramsData.companyParams.licenseValidDate = license_valid_date_start + "," + license_valid_date_end;
				}
			}
			 
			//证件照有效期校验
			var legal_idcard_valid_date_start = this.initData.legal_idcard_valid.legal_idcard_valid_date_start;//证件照开始时间
			var legal_idcard_valid_date_end =   this.initData.legal_idcard_valid.legal_idcard_valid_date_end;  //证件照结束时间
			var legal_idcard_valid_date = this.initData.legal_idcard_valid.legal_idcard_valid_date;  //证件照结束时间是否长期  false不是长期  true是长期
			if("" == legal_idcard_valid_date_start){
				this.$message.error('请选择证件照开始时间！');
				return;
			} 
			if(JSON.parse(legal_idcard_valid_date)){
				//证件照是长期
				this.paramsData.companyParams.legalIdcardValidDate = legal_idcard_valid_date_start+",长期";
			}else{
				//证件照不是长期
				if("" == legal_idcard_valid_date_end || null == legal_idcard_valid_date_end){
					this.$message.error('请选择证件照结束时间！');
					return;
				}else{
					this.paramsData.companyParams.legalIdcardValidDate = legal_idcard_valid_date_start + "," + legal_idcard_valid_date_end;
				}
			}
			
			
			createRegisterEnterprise(this.paramsData.companyParams).then(res =>{
				//if(res){
					//this.resData.order_date = res.order_date;
					//this.resData.order_id = res.order_id;
					//this.dialogVisibleCreateUser = true;//打开提交开户验证码信息
					//this.getUserTypeClick();
				//}
				if(""==res.data){
					this.$message('提交成功，请操作签约！'); 
					//关闭表单
					this.bodyData.formOpen = false;
					//this.resData.order_date = res.order_date;
					//this.resData.order_id = res.order_id;
					//this.dialogVisibleCreateUser = true;//打开提交开户验证码信息
					this.getUserTypeClick();
					this.bodyData.formSubmitOpen = false;//关闭提交按钮
				}
			})
		}
		
		if("0" == dataType){
			//个人数据提交
			if("" == this.paramsData.userParams.name){
				this.$message.error('姓名不能为空！');
				return;
			}
			if("" == this.paramsData.userParams.idCard){
				this.$message.error('身份证号码不能为空！');
				return;
			}
			if("" == this.paramsData.userParams.mobile){
				this.$message.error('手机号不能为空！');
				return;
			}
			if(1 != this.paramsData.userParams.file.idCardFrontList.length){
				this.$message.error('请上传身份证正面（人像面）照片！');
				return;
			}
			if(1 != this.paramsData.userParams.file.idCardBackList.length){
				this.$message.error('请上传身份证反面（国徽面）照片！');
				return;
			}
			var id_card_valid_date_start = this.initData.id_card.id_card_valid_date_start;//身份证开始时间
			var id_card_valid_date_end =   this.initData.id_card.id_card_valid_date_end;  //身份证结束时间
			var id_card_valid_date = this.initData.id_card.id_card_valid_date;  //身份证结束时间是否长期  false不是长期  true是长期
			if("" == id_card_valid_date_start){
				this.$message.error('请选择身份证开始时间！');
				return;
			}
			 
			if(JSON.parse(id_card_valid_date)){
				//身份证是长期
				this.paramsData.userParams.idCardValidDate = id_card_valid_date_start+",长期";
			}else{
				//身份证不是长期
				if("" == id_card_valid_date_end || null == id_card_valid_date_end){
					this.$message.error('请选择身份证到期时间！');
					return;
				}else{
					this.paramsData.userParams.idCardValidDate = id_card_valid_date_start + "," + id_card_valid_date_end;
				}
				
			}
			if("" == this.paramsData.userParams.bankAccount){
				this.$message.error('银行卡号不能为空！');
				return;
			}
			if("" == this.paramsData.userParams.bankAccountName){
				this.$message.error('开户行不能为空！');
				return;
			}
			createRegister(this.paramsData.userParams).then(res =>{
				//alert("res==="+JSON.stringify(res))
				if(""==res.data){
					this.$message('提交成功，请签约！'); 
					//关闭表单
					this.bodyData.formOpen = false;
					//this.resData.order_date = res.order_date;
					//this.resData.order_id = res.order_id;
					//this.dialogVisibleCreateUser = true;//打开提交开户验证码信息
					this.getUserTypeClick();
					this.bodyData.formSubmitOpen = false;//关闭提交按钮
				}
			})
		}
		
	},
	//删除身份证正面照片
	handleRemoveShenfenzhengzhengmian(file,row){
		let index = this.paramsData.userParams.file.idCardFrontList.findIndex((ele) =>{
			return ele.url === file.url;
		})
		this.paramsData.userParams.file.idCardFrontList.splice(index, 1);
	},
	
	//删除身份证反面照片
	handleRemoveShenfenzhengFanmian(file,row){
		let index = this.paramsData.userParams.file.idCardBackList.findIndex((ele) =>{
			return ele.url === file.url;
		})
		this.paramsData.userParams.file.idCardBackList.splice(index, 1);
	},
	//营业执照删除
	handleRemoveYingyezhizhao(file,row){
		let index = this.paramsData.companyParams.file.licenseList.findIndex((ele) =>{
			return ele.url === file.url;
		})
		this.paramsData.companyParams.file.licenseList.splice(index, 1);
	},
	//证件照正面删除
	handleRemoveZhengJianZhaoZhengMian(file,row){
		let index = this.paramsData.companyParams.file.idCardFrontList.findIndex((ele) =>{
			return ele.url === file.url;
		})
		this.paramsData.companyParams.file.idCardFrontList.splice(index, 1);
	},
	//证件照反面删除
	 handleRemoveZhengJianZhaoFanMian(file,row){
		let index = this.paramsData.companyParams.file.idCardBackList.findIndex((ele) =>{
			return ele.url === file.url;
		})
		this.paramsData.companyParams.file.idCardBackList.splice(index, 1);
	},
	
	//发起签约请求**************************************************************************************************************
	signHttp(){
		var verifyCode = this.initData.signData.verifyCode;//签约短信验证码
		if("" == verifyCode || verifyCode.length<4 || verifyCode.length>8){
			this.$message.error('短信验证码不能为空或长度不能低于4位不能大于8位！');
			return;
		}
		// var params = {
		// 	id:this.initData.initUserData.user_data_id,//签约数据id
		// 	userAuthType:this.initData.initUserData.user_auth_type,//签约类型 
		// 	verifyCode:verify_code//接收验证码
		// }
		getUserSign(this.initData.signData).then(res =>{
			//alert("res==="+JSON.stringify(res.data))
			if("" == res.data){
				this.$message('签约提交成功，请等待审核！');
				this.dialogVisibleCreateUser = true;
				//alert("res=1=="+JSON.stringify(res))
			}else{
				//alert("res=2=="+JSON.stringify(res))	
			}			
		}) 
	},
	//发起签约请求按钮
	sign(){
		var userAuthType = this.bodyData.userAuthType;//0 2 3	 //获取的是初始化的签约类型
		this.initData.signData.userAuthType = userAuthType;//签约类型赋值
		this.initData.verifyCode = "";
		this.dialogVisibleCreateUser = true;
		if("0" == userAuthType){
			//个人签约
			this.initData.signData.id = this.bodyData.userData.id;
			this.initData.signData.mobile = this.bodyData.userData.mobile;
			//签约验证码是自动的
		}else if("2" == userAuthType || "3" == userAuthType){
			//企业和个体户签约
			this.initData.signData.id = this.bodyData.companyData.id;
			this.initData.signData.mobile = this.bodyData.companyData.legalMobile;
			
			//签约验证码是自动的
		}else{
			alert("签约功能未开放")
		}
		
	},
	//修改主体资料按钮
	updateUserData(){ 
		//this.initData.userData = true;
		//this.initData.org_type = false;//不支持修改主体
		if("0" == this.bodyData.userAuthType){
			//打开表单
			this.bodyData.formOpen = true;
			this.bodyData.isUpdateData.user.usersName = false;
			this.bodyData.isUpdateData.user.idCard = false;
			//个人资料
			this.paramsData.userParams.usersName = this.bodyData.userData.usersName;//姓名
			this.paramsData.userParams.idCard = this.bodyData.userData.idCard;//身份证
			this.paramsData.userParams.mobile = this.bodyData.userData.mobile;//手机号
			this.paramsData.userParams.file.idCardFrontList = [];
			this.paramsData.userParams.file.idCardFrontList.push(JSON.parse(this.bodyData.userData.idCardFrontMediaId));//身份证正面json数据
			this.paramsData.userParams.file.idCardBackList = [];
			this.paramsData.userParams.file.idCardBackList.push(JSON.parse(this.bodyData.userData.idCardBackMediaId));//身份证反面json数据
			//身份证时间 2022-07-25,2023-08-09  
			var idCardValidDate = this.bodyData.userData.idCardValidDate;
			//身份证开始时间
			this.initData.id_card.id_card_valid_date_start = idCardValidDate.split(",")[0]
			if("长期" == idCardValidDate.split(",")[1]){
				//身份证结束时间
				this.initData.id_card.id_card_valid_date_end = "";
				//身份证是否长期
				this.initData.id_card.id_card_valid_date = true;
			}else{
				//身份证长期时间重新赋值
				this.initData.id_card.id_card_valid_date_end = idCardValidDate.split(",")[1];
			}
			this.paramsData.userParams.bankAccount = this.bodyData.userData.bankAccount;//银行卡号
			this.paramsData.userParams.bankAccountName = this.bodyData.userData.bankAccountName;//开户名称
			this.paramsData.userParams.bankCode = this.bodyData.userData.bankCode;//银联编码
			this.paramsData.userParams.cnapsCode = this.bodyData.userData.cnapsCode;//银联号
			
			this.bodyData.formSubmitOpen = true;//打开表单提交按钮
			//点击查看主体状态获取到的认证状态
			var accountStatus = this.bodyData.userData.accountStatus;
			//alert("accountStatus=="+accountStatus)
			if("1" == accountStatus ||  "5" == accountStatus || "6" == accountStatus || "7" == accountStatus || "8" == accountStatus || "10" == accountStatus ){
				//8审核中，10已通过--不显示提交按钮
				this.bodyData.formSubmitOpen = false;//关闭表单的提交
			}
			
		}else if("2" == this.bodyData.userAuthType || "3" == this.bodyData.userAuthType){
			//企业资料
			//打开表单
			this.bodyData.formOpen = true;
			this.paramsData.dataType = this.bodyData.userAuthType;
			this.paramsData.companyParams.shortName = this.bodyData.companyData.shortName;
			this.paramsData.companyParams.licenseNo = this.bodyData.companyData.licenseNo;
			this.paramsData.companyParams.licenseName = this.bodyData.companyData.licenseName;
			this.paramsData.companyParams.licenseCompanyAddress = this.bodyData.companyData.licenseCompanyAddress;
			
			var licenseValidDate = this.bodyData.companyData.licenseValidDate;//营业执照时间
			//alert("==="+licenseValidDate)
			this.initData.license_valid.license_valid_date_start = licenseValidDate.split(",")[0]
			if("长期" == licenseValidDate.split(",")[1]){
				//营业执照结束时间
				this.initData.license_valid.license_valid_date_end = "";
				//营业执照是否长期
				this.initData.license_valid.license_valid_date = true;
			}else{
				//身份证长期时间重新赋值
				this.initData.license_valid.license_valid_date_end = licenseValidDate.split(",")[1];
			}
			//营业执照
			this.paramsData.companyParams.file.licenseList = [];
			this.paramsData.companyParams.file.licenseList.push(JSON.parse(this.bodyData.companyData.licenseMediaId));
			//账户类型
			this.paramsData.companyParams.bankcardType = this.bodyData.companyData.bankcardType;
			this.paramsData.companyParams.bankAccount = this.bodyData.companyData.bankAccount;
			this.paramsData.companyParams.bankAccountName = this.bodyData.companyData.bankAccountName;
			this.paramsData.companyParams.bankCode = this.bodyData.companyData.bankCode;
			this.paramsData.companyParams.cnapsCode = this.bodyData.companyData.cnapsCode;
			this.paramsData.companyParams.legalName = this.bodyData.companyData.legalName;
			this.paramsData.companyParams.legalIdcardType = this.bodyData.companyData.legalIdcardType;
			this.paramsData.companyParams.legalIdcard = this.bodyData.companyData.legalIdcard;
			//证件照正面
			this.paramsData.companyParams.file.idCardFrontList = [];
			this.paramsData.companyParams.file.idCardFrontList.push(JSON.parse(this.bodyData.companyData.idCardFrontMediaId));
			this.paramsData.companyParams.file.idCardBackList = [];
			this.paramsData.companyParams.file.idCardBackList.push(JSON.parse(this.bodyData.companyData.idCardBackMediaId));
			//证件有效期开始
			var legal_idcard_valid_date = this.bodyData.companyData.legalIdcardValidDate;//证件有效期
			//alert("legal_idcard_valid_date=="+legal_idcard_valid_date)
			this.initData.legal_idcard_valid.legal_idcard_valid_date_start = legal_idcard_valid_date.split(",")[0]
			
			 if("长期" == legal_idcard_valid_date.split(",")[1]){
			 	//证件有效期结束时间
			 	this.initData.legal_idcard_valid.legal_idcard_valid_date_start_end = "";
			 	//证件有效期是否长期
			 	this.initData.legal_idcard_valid.legal_idcard_valid_date = true;
			 }else{
			 	//证件有效期长期时间重新赋值
			 	this.initData.legal_idcard_valid.legal_idcard_valid_date_end = legal_idcard_valid_date.split(",")[1];
			 }
			 this.paramsData.companyParams.legalMobile = this.bodyData.companyData.legalMobile;
			 this.paramsData.companyParams.contactEmail = this.bodyData.companyData.contactEmail;
			 
			 this.bodyData.formSubmitOpen = true;//打开表单提交按钮
			 //点击查看主体状态获取到的认证状态
			 var accountStatus = this.bodyData.companyData.accountStatus;
			 if("1" == accountStatus || "5" == accountStatus || "6" == accountStatus || "7" == accountStatus || "8" == accountStatus || "10" == accountStatus ){
			 	//8审核中，10已通过--不显示提交按钮
			 	this.bodyData.formSubmitOpen = false;//关闭表单的提交
			 }
			 
		}else{
			alert("功能未开放2");
		}
	},
	//获取获取认证状态--认证状态
	getAccountStatus(accountStatus){
		if("1" == accountStatus){
			return "鉴权成功";
		}else if("2" == accountStatus){
			return "鉴权失败";
		}else if("3" == accountStatus){
			return "待电子签约";
		}else if("4" == accountStatus){
			return "电子签约失败";
		}else if("5" == accountStatus){
			return "电子签约成功";
		}else if("6" == accountStatus){
			return "资料校验中";
		}else if("7" == accountStatus){
			return "待账户验证 (四要素鉴权)";
		}else if("8" == accountStatus){
			return "审核中";
		}else if("9" == accountStatus){
			return "处理中";
		}else if("10" == accountStatus){
			return "已通过";
		}else if("11" == accountStatus){
			return "进件失败，请联系客服"+accountStatus;
		}else if("12" == accountStatus){
			return "进件失败,请联系客服"+accountStatus;
		}else{
			return "未知类型"+accountStatus;
		} 
	},
	//获取用户认证类型 认证类型：0个人，3企业，2个体户
	getUserAuthType(method){
		if("0" == method){
			return "个人用户";
		}else if("3" == method){
			return "企业用户";
		}else if("2" == method){
			return "个体用户";
		}else{
			return "未知类型"+method;
		}
	},
	//初始化
	init(){
		this.getUserTypeClick();
	},
	//认证信息获取
	getUserTypeClick(){
		var params = {}
		getUserType(params).then(res =>{
			if("" == res.user_auth_type && ""==res.user_data_id){
				//当前帐号未认证提交数据
				this.bodyData.formOpen = true;//打开认证表单
				this.bodyData.authDataOpen = true;//打开主体类型
				this.bodyData.formSubmitOpen = true;//打开表单的提交
			}else{
				if("0" == res.data.user_auth_type){
					//个人小微用户
					this.bodyData.userOpen = true;//个人已经认证的数据打开
					this.bodyData.userData = res.data.userData;//个人认证数据
					this.bodyData.userAuthType = res.data.user_auth_type;//认证类型：0个人，3企业，2个体户
					
				}else if("3" == res.data.user_auth_type || "2" == res.data.user_auth_type){
					//企业的
					this.bodyData.companyOpen = true;//个人已经认证的数据打开
					this.bodyData.companyData = res.data.userData;//企业和个体户认证的数据
					this.bodyData.userAuthType = res.data.user_auth_type;//认证类型：0个人，3企业，2个体户
				}else{
					alert("功能未开放")
				}
				
				//alert("res=="+JSON.stringify(res.data.user_auth_type))
			}
			//认证类型：0个人，3企业，2个体户
			if("3" == res.user_auth_type || "2" == res.user_auth_type){
				//企业和个体户
				
			}else{
				//小微  个人
			}
			// if(res.user_auth_type){
			// 	//未实名  打开表单 关闭表头
			// 	this.initData.dataTitle = false;//关闭表头
			// 	this.initData.userData = false;//打开表单
				 
			// }else{
			// 	//已实名 打开表头 关闭表单
				 
			// 	this.initData.dataTitle = false;//关闭表头
			// 	this.initData.userData = true;//打开表单
			// }
			// //alert("res=="+JSON.stringify(res.data))
			// this.initData.initUserData = res.data.userData;
		})
	},
	//通过认证类型和数据id获取认证数据
	getUserData(user_auth_type,user_data_id){
		
	},
	
	//文件上传
	httpRequestFile(data){
		//alert("data==="+data.data)
		let params = new FormData();
		params.append("file", data.file); 
		qftUploadFile(params).then(result =>{
			//企业和个体户的
			if("yingyezhizhao" == data.data){
				var initData = {//营业执照
					"media_id":result.media_id,
					"url":result.url
				}
				this.paramsData.companyParams.file.licenseList.push(initData);
			}
			if("zhengjianzhengmianzhao" == data.data){
				var initData = {//证件照正面
					"media_id":result.media_id,
					"url":result.url
				} 
				   //paramsData.companyParams.file.idCardFrontList
				this.paramsData.companyParams.file.idCardFrontList.push(initData);
				
			}
			if("zhengjianfanmianzhao" == data.data){
				var initData = {//证件照反面
					"media_id":result.media_id,
					"url":result.url
				} 
				this.paramsData.companyParams.file.idCardBackList.push(initData);
			}
			
			
			//以下是个人的
			if("shenfenzhengzhengmian" == data.data){
				//身份证正面照----个人的
				console.log("shenfenzhengzhengmian");
				var initData = {
					"media_id":result.media_id,
					"url":result.url
				}
				this.paramsData.userParams.file.idCardFrontList.push(initData);
			}
			if("shenfenzhengfanmian" == data.data){
				//身份证反面照---个人的
				console.log("shenfenzhengfanmian");
				var initData = {
					"media_id":result.media_id,
					"url":result.url
				}
				this.paramsData.userParams.file.idCardBackList.push(initData);
			}
			// alert(result)
			// alert("result=="+JSON.stringify(result))
		})
		
	},
	//+ 通用加号放大
	handlePictureCardPreview(file){
	  this.dialogImageUrl = file.url;
	  this.dialogVisible = true;
	},
	//关闭操作
	handleClose(done) {
	        this.$confirm('确认关闭？')
	          .then(_ => {
	            done();
	          })
	          .catch(_ => {});
	}, 
  },
  computed:{
     
  }
}
</script>

<style>
 
</style>