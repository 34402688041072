<template>
  <div>
		<router-view></router-view>
  </div> 
</template>
<script>
import CommonAside from "@/components/CommonAside";
export default {
  name: "Main",
  components:{
    CommonAside
  }
}
</script>
<style lang="scss">

html,body,h3{
  margin: 0;
  padding: 0;
}

</style>
