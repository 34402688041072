<template>
  <div class="hello">
      <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#fafafa"
          text-color="#181818"
          active-text-color="#00aa00">
		<h3 style="color: #181818; font-weight: 600;">
			<!-- <i class="el-icon-folder-opened"></i> -->
			【易付鸟-API】
		</h3>
		
        <el-menu-item v-for="item in noChildren" :key="item.name" :index="item.name" @click="menuClick(item)">
          <i :class="`el-icon-${item.icon}`"></i>
          <span slot="title">{{item.label}}</span>
        </el-menu-item>
  
		<!-- 有子菜单 -->
		<!-- =={{hasChildren}}== -->
        <el-submenu v-for="item in hasChildren" :key="item.name" :index="item.name" >
          <template slot="title">
            <i :class="`el-icon-${item.icon}`"></i>
            <span>{{item.label}}</span>
          </template>
          <el-menu-item-group v-for="subItem in item.children" :key="subItem.name">
            <el-menu-item :index="subItem.name" @click="menuClick(subItem)">{{subItem.label}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div> 
</template>

<script>
export default {
  data() {
    return {
      isCollapse: true,
      menuData: [ 
		{
          path: '/home',
          name: 'home',
          label: '接口接入介绍',
          icon: 'folder-opened',
          url: '/home'
        },
		{
		  path: '/getShopNum',
		  name: 'getShopNum',
		  label: '获取商户号',
		  icon: 'folder-opened',
		  url: '/getShopNum'
		},
		
		{
		  path: '/orderQuery',
		  name: 'orderQuery',
		  label: '订单查询',
		  icon: 'folder-opened',
		  url: '/orderQuery'
		},
		{
		  path: '/payNotifyUrl',
		  name: 'payNotifyUrl',
		  label: '支付结果异步通知',
		  icon: 'folder-opened',
		  url: '/payNotifyUrl'
		},
		{
		  path: '/orderClose',
		  name: 'orderClose',
		  label: '订单关闭',
		  icon: 'folder-opened',
		  url: '/orderClose'
		},
		{
		  path: '/orderUnifyRefund',
		  name: 'orderUnifyRefund',
		  label: '订单退款',
		  icon: 'folder-opened',
		  url: '/orderUnifyRefund'
		},
		{
		  path: '/orderUnifyRefundQuery',
		  name: 'orderUnifyRefundQuery',
		  label: '退款查询',
		  icon: 'folder-opened',
		  url: '/orderUnifyRefundQuery'
		}, 
		{
			label: '创建交易',
			icon: 'folder-opened',   
			name: 'jhzfObj',
			children:[ 
				{
				  path: '/objPay',
				  name: 'objPay',
				  label: '└聚合收银台',
				  icon: 'folder-opened',
				  url: ''
				}
			]
		},
        {
           label: '分账管理',
           icon: 'folder-opened',   
		   name: 'fzgl',
           children:[ 
			 {
			   path: '/ledger/openLedger',
			   name: 'openLedger',
			   label: '└分账开通',
			   icon: 's-shop',
			   url: ''
			 },
			 {
			   path: '/ledger/ledgerFileCode',
			   name: 'ledgerFileCode',
			   label: '└附件上传',
			   icon: 's-shop',
			   url: ''
			 },
			 {
			   path: '/ledger/ledgerAddUser',
			   name: 'ledgerAddUser',
			   label: '└分账接收方创建',
			   icon: 's-shop',
			   url: ''
			 },
			 {
			   path: '/ledger/cardBin',
			   name: 'cardBin',
			   label: '└接收方卡bin信息',
			   icon: 's-shop',
			   url: ''
			 },
			 {
			   path: '/ledger/applyBind',
			   name: 'applyBind',
			   label: '└分账接收方绑定',
			   icon: 's-shop',
			   url: ''
			 },
			 {
			   path: '/ledger/applyUnBind',
			   name: 'applyUnBind',
			   label: '└分账接收方解绑',
			   icon: 's-shop',
			   url: ''
			 }
           ]
         },
		 {
		 	label: '订单分账',
		 	icon: 'folder-opened',
		 	children:[
		 		{
		 		  path: '/ledger/separate',
		 		  name: 'separate',
		 		  label: '└订单分账',
		 		  icon: 's-shop',
		 		  url: ''
		 		},
		 		// {
		 		//   path: '/ledger/sacsCancel',
		 		//   name: 'sacsCancel',
		 		//   label: '2>订单分账撤销',
		 		//   icon: 's-shop',
		 		//   url: ''
		 		// },
		 		// {
		 		//   path: '/ledger/sacsFallback',
		 		//   name: 'sacsFallback',
		 		//   label: '3>订单分账回退',
		 		//   icon: 's-shop',
		 		//   url: ''
		 		// },
		 		
				{
				  path: '/ledger/sacsQueryAmt',
				  name: 'sacsQueryAmt',
				  label: '└订单可分账金额查询',
				  icon: 's-shop',
				  url: ''
				},
				{
				  path: '/ledger/sacsQuery',
				  name: 'sacsQuery',
				  label: '└订单分账结果查询',
				  icon: 's-shop',
				  url: ''
				},
		 	]
		 },
		 {
		 	label: '钱包提现',
		 	icon: 'folder-opened',
		 	name: 'qbtx',
		 	children:[  
		 		// {
		 		//   path: '/ledger/ewalletWithdraw',
		 		//   name: 'ewalletWithdraw',
		 		//   label: '└分帐后账户提现',
		 		//   icon: 's-shop',
		 		//   url: ''
		 		// },
				{
				  path: '/ledger/ewalletWithdrawD1',
				  name: 'ewalletWithdrawD1',
				  label: '└提现D1',
				  icon: 's-shop',
				  url: ''
				},
				{
				  path: '/ledger/ewalletWithdrawQueryOk',
				  name: 'ewalletWithdrawQueryOk',
				  label: '└提现结果通知',
				  icon: 's-shop',
				  url: ''
				},
		 		{
		 		  path: '/ledger/ewalletWithdrawQuery',
		 		  name: 'ewalletWithdrawQuery',
		 		  label: '└提现结果查询',
		 		  icon: 's-shop',
		 		  url: ''
		 		},
		 		
		 		{
		 		  path: '/ledger/ewalletSettleProfile',
		 		  name: 'ewalletSettleProfile',
		 		  label: '└提现模式设置',
		 		  icon: 's-shop',
		 		  url: ''
		 		},
		 		
		 		{
		 		  path: '/ledger/settleQuery',
		 		  name: 'settleQuery',
		 		  label: '└提现模式查询',
		 		  icon: 's-shop',
		 		  url: ''
		 		}, 
				
		 		{
		 		  path: '/ledger/ewalletBalanceQuery',
		 		  name: 'ewalletBalanceQuery',
		 		  label: '└账户余额查询',
		 		  icon: 's-shop',
		 		  url: ''
		 		},
		 		// {
		 		//   path: '/ledger/queryAcctInfo',
		 		//   name: 'queryAcctInfo',
		 		//   label: '└待结算余额查询',
		 		//   icon: 's-shop',
		 		//   url: ''
		 		// },
		 		// {
		 		//   path: '/ledger/ewalletSettleProfile',
		 		//   name: 'ewalletSettleProfile',
		 		//   label: '└提现模式设置',
		 		//   icon: 's-shop',
		 		//   url: ''
		 		// },
		 		// {
		 		//   path: '/ledger/queryAcctInfo',
		 		//   name: 'queryAcctInfo',
		 		//   label: '└可用余额定向转账',
		 		//   icon: 's-shop',
		 		//   url: ''
		 		// }
		 	]
		},
		{
		  path: '/ledger/main/authenApi',
		  name: 'authenApi',
		  label: '信息鉴权',
		  icon: 'folder-opened',
		  url: '/ledger/main/authenApi'
		},
      ]
    };
  },
  
 
  
  
  methods: {
	menuClick(item) { 
		if(this.$route.path !== item.path && !(this.$route.path === '/home' && item.path === '/')){ 
			this.$router.push(item.path)
		}
	},
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  computed:{
    // 没有子菜单
    noChildren(){
      return this.menuData.filter(item => !item.children)
    },
    // 有子菜单
    hasChildren(){
		 
      return this.menuData.filter(item => item.children)
    }
  }
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 500px;
    
}
.el-menu{
  height: 100vh;
  overflow-y: auto; /* 启用垂直滚动条 */
  overflow-x: hidden;
  h3{
        color: #fff;
	    text-align: center;
    	line-height: 48px;
    	font-size: 16px;
   	 	font-weight: 400;
		
  }
}
</style> 


