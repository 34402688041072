<template>
    <div class="body">
		 
    </div>
</template>

<script>
import { login,regedit,getCodes,getContract } from "../../http/api"
export default {
  name: "Login",
    data(){
      return{
		   
      }
  },
	created() {
		this.init();
		 
	},
    methods:{
	   init(){
		   window.location.href = window.location.href+"home"
	   }
    }
};
</script>

<style lang="less" scoped>
	  
    .loginContainer{
        border-radius: 15px;
        background-clip: padding-box;
        margin: 180px auto;
        width: 450px;
        padding: 15px 35px 15px 35px;
        background: aliceblue;
        border:1px solid #ffffff;
        box-shadow: 10px 0 250px #000000;
    }
    .loginTitle{
        margin: 0px auto 48px auto;
        text-align: center;
        font-size: 40px;
    }
    .loginRemember{
        text-align: left;
        margin: 0px 0px 15px 0px;
    }
	.body{
		position: absolute;
			top: 0;
			left: 0;
			background-color: #ffffff;
			height: 100%;width: 100%;
			background-image: url('../../assets/logo/301.jpeg');background-size: 100% auto;
	}
	 
	
     
</style> 