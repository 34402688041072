import axios from "axios";
import env from "./env"; 
import router from '../router'
import {
	Message,MessageBox, Notification
} from 'element-ui'
//这里是私有域名  但是也可以不写
var vipUrl = "/dev-api-pc";
// 创建axios实例

var baseUrl=vipUrl+env.dev.baseUrl

axios.defaults.baseURL="http://payment.sxjhpay.com/payment/qft-merchant";//线上环境http://payment.sxjhpay.com/payment/
//axios.defaults.baseURL="http://localhost:32510/qft-merchant";//本地*****环境


const service = axios.create({ 
	headers: {
		"satoken": localStorage.getItem('Authorization')
	}, //请求头
	settimeout: 20000, //超时时间
});

// 添加请求拦截器
service.interceptors.request.use(
	config => {
		// 在发送请求之前做些什么
		//config.headers["deviceType"] = "H5000";
		config.headers["Authorization"] = localStorage.getItem('Authorization');
		config.headers["satoken"] = localStorage.getItem('Authorization');
		console.log("请求的数据:", config);
		return config;
	},
	error => {
		// 对请求错误做些什么
		return Promise.reject("出错", error);
	}
);

// 添加响应拦截器   4-19   5-19  6-19   7-19  8-19
service.interceptors.response.use(
	response => {
		// 对响应数据做点什么
		// console.log("返回的数据", response);
		if (response.data.code == 8888) {
			//后台返回200，去掉双层data，否则全部显示
			if(response.data.data){
				return Promise.resolve(response.data.data);
			}else{
				//否则全部显示原来的数据
				return Promise.resolve(response.data.data);
			}
		}else if(10000 == response.data.code || 20000 == response.data.code){
			Message.error({
				message:response.data.message
			});
			//return Promise.resolve(null);
		}else if(response.data.code == 401){
			router.replace({
			  path: '/login/login',
			  // 登录成功后跳入浏览的当前页面
			  query: {redirect: router.currentRoute.fullPath}
			});
			return;
		}else if(response.data.code == 500){
			Message.error({
				message: '系统快要处理不过来了，请刷新后继续尝试>>提示：'+response.data.msg
			});
		}else{
			return Promise.resolve(response);
		}
		//return response;
	},
	error => {
		//alert("error=="+JSON.stringify(error.response))
		// 对响应错误做点什么
		// if(error.response.code){
			
		// }else{
		// 	alert("error=="+error)
		// }
		Message.error({
			//message: '网络异常，请刷新后继续尝试'
			message: '登录信息已过期或数据权限检测部分不通过！请重新登录'
		});
		return Promise.reject(error);
	}
);
export default service;
